import 'hammerjs';

export default function lightbox(selector, settings) {
  settings = settings || {};
  settings = Object.assign({
    preload: [-1, 0, 1],
    ofLabel: 'of',
    previousLabel: 'previous',
    nextLabel: 'next',
    closeLabel: 'close'
  }, settings || {});

  if (selector && document.querySelector(selector)) {
    const lightbox = {
      input: false,
      previous: 0,
      current: 1,
      next: 0,
      count: 0,
      images: 0,
      clickExtras: true,
      els: {},
      eventHandlers: {},

      init: () => {
        lightbox.els.html = document.querySelector('html');
        lightbox.els.body = document.querySelector('body');

        (lightbox.els.root = document.createElement('div')).classList.add('lightbox');

        lightbox.els.root.innerHTML = `<p class="counter"><strong></strong> ${settings.ofLabel} <span></span></p>` +
          `<a href="#prev" class="arrows prev">${settings.previousLabel}</a><a href="#next" class="arrows next">${settings.nextLabel}</a>` +
          `<a href="#close" class="close">${settings.closeLabel}</a>` +
          '<div class="wrap"></div>';

        lightbox.els.wrap = lightbox.els.root.querySelector('.wrap');
        lightbox.els.current = lightbox.els.root.querySelector('.counter strong');
        lightbox.els.count = lightbox.els.root.querySelector('.counter span');
        lightbox.els.arrows = lightbox.els.root.querySelectorAll('.arrows');
        lightbox.els.close = lightbox.els.root.querySelector('.close');

        lightbox.els.body.addEventListener('click', event => {
          if (event.target.matches('.lightbox .arrows')) {
            event.preventDefault();

            const direction = event.target.matches('.next') ? 'next' : 'prev';
            lightbox.input = { type: 'click/tap', action: 'slide', element: 'arrow', direction: direction };
            lightbox.step(direction);
          } else if (event.target.matches('.lightbox .close')) {
            event.preventDefault();

            lightbox.close();
          } else if (event.target.matches('.lightbox .item, .lightbox .item *') && lightbox.clickExtras) {
            event.preventDefault();

            if (event.target.matches('figcaption, figcaption *')) {
              lightbox.input = { type: 'click/tap', action: 'captions', element: 'caption' };
              lightbox.els.root.classList.contains('captions') ? lightbox.els.root.classList.remove('captions') : lightbox.els.root.classList.add('captions');
            } else {
              lightbox.input = { type: 'click/tap', action: 'extras', element: 'item' };
              lightbox.els.root.classList.contains('extras') ? lightbox.els.root.classList.remove('extras') : lightbox.els.root.classList.add('extras');
            }
          }
        });
      },

      open: el => {
        lightbox.trigger('open');

        if (!lightbox.els.root) lightbox.init();

        let items = Array.prototype.slice.call(document.querySelectorAll(selector), 0).filter(thisEl => el.dataset.set ? (thisEl.dataset.set === el.dataset.set) : !thisEl.dataset.set);
        const dups = [ el.dataset.href ];

        items = items.filter(thisEl => {
          if (thisEl === el) return true;

          const href = thisEl.dataset.href;
          if (dups.includes(href)) return false;

          dups.push(href);
          return true;
        });

        let clicked = items.indexOf(el);

        lightbox.build(items, clicked);
        lightbox.els.body.appendChild(lightbox.els.root).classList.add('extras');
        lightbox.els.html.classList.add('lightbox-active');
        lightbox.preload();

        lightbox.addEventListener(window, 'mousewheel', event => event.preventDefault());

        lightbox.addEventListener(lightbox.els.body, 'keydown', event => {
          if (event.which === 37) { // left
            event.preventDefault();
            lightbox.input = { type: 'keydown', key: 'left', action: 'slide', direction: 'prev' };
            lightbox.step('prev');
          } else if (event.which === 39 || event.which === 32) { // right or space
            event.preventDefault();
            lightbox.input = { type: 'keydown', key: (event.which === 32 ? 'space' : 'right'), action: 'slide', direction: 'next' };
            lightbox.step('next');
          } else if (event.which === 38 || event.which === 40) { // up or down
            event.preventDefault();
            lightbox.input = { type: 'keydown', key: (event.which === 38 ? 'up' : 'down'), action: 'captions' };
            lightbox.els.root.toggleClass('captions');
          } else if (event.which === 27) { // escape
            event.preventDefault();
            lightbox.input = { type: 'keydown', key: 'escape', action: 'close' };
            lightbox.close();
          }
        });

        if (!lightbox.els.html.classList.contains('mie10')) {
          lightbox.clickExtras = false;

          /* global Hammer:false */
          const hammertime = new Hammer(lightbox.els.wrap);

          hammertime.on('panend', event => {
            event.preventDefault();

            if (event.distance > 50) {
              if (event.direction === 2) {
                lightbox.input = { type: 'touch', gesture: 'pan', action: 'slide', direction: 'next' };
                lightbox.step('next');
              } else if (event.direction === 4) {
                lightbox.input = { type: 'touch', gesture: 'pan', action: 'slide', direction: 'prev' };
                lightbox.step('prev');
              }
            }
          });

          hammertime.on('tap', event => {
            event.preventDefault();

            if (event.target.matches('figcaption, figcaption *')) {
              lightbox.input = { type: 'tap', action: 'captions', element: 'caption' };
              lightbox.els.root.classList.contains('captions') ? lightbox.els.root.classList.remove('captions') : lightbox.els.root.classList.add('captions');
            } else {
              lightbox.input = { type: 'tap', action: 'extras', element: 'wrap' };
              lightbox.els.root.classList.contains('extras') ? lightbox.els.root.classList.remove('extras') : lightbox.els.root.classList.add('extras');
            }
          });

          hammertime.on('touchmove', event => {
            if (event.target.matches('figcaption, figcaption *')){
              const figcaption = event.target.matches('figcaption') ? event.target : event.target.closest('.figcaption');
              if (figcaption.querySelector('span').offsetHeight <= (figcaption.offsetHeight() + 5)) {
                event.preventDefault();
              }
            } else {
              event.preventDefault();
            }
          });
        }

        lightbox.trigger('opened');
      },

      close: () => {
        lightbox.trigger('close');

        lightbox.current = 1;
        lightbox.count = 0;
        lightbox.images = 0;
        lightbox.set = false;
        delete lightbox.els.items;

        lightbox.els.root.parentNode.removeChild(lightbox.els.root);
        lightbox.els.root.classList.remove('single', 'extras');

        for (let item of lightbox.els.root.querySelectorAll('.item')) {
          item.parentNode.removeChild(item);
        }

        lightbox.els.html.classList.remove('lightbox-active');

        lightbox.removeEventListeners(lightbox.els.body, 'keydown');
        lightbox.removeEventListeners(window, 'mousewheel');

        lightbox.trigger('closed');
      },

      build: (items, clicked) => {
        let itemsHtml = '';
        let currentImage = 0;

        items.forEach((thisEl, index) => {
          const title = (thisEl.querySelector('figcaption') && thisEl.querySelector('figcaption').innerHTML) || (thisEl.attributes.getNamedItem('title') && thisEl.attributes.getNamedItem('title').value) || '';

          itemsHtml += `<div data-slide="${lightbox.count + 1}" data-image="${lightbox.images + 1}" class="item${index === clicked ? ' active' : ''}">` +
            `<figure><img data-src="${thisEl.dataset.href}" alt="${title}" title="${title}">`;

          if (title) itemsHtml += `<figcaption>${title}</figcaption></figure>`;

          itemsHtml += '</div>';

          lightbox.count++;
          lightbox.images++;

          if (index === clicked) {
            lightbox.current = lightbox.count;
            currentImage = lightbox.images;
          }
        });

        lightbox.els.wrap.innerHTML = itemsHtml;
        lightbox.els.items = lightbox.els.wrap.querySelectorAll('.item');
        lightbox.els.current.innerHTML = currentImage;
        lightbox.els.count.innerHTML = lightbox.images;

        if (lightbox.count === 1) lightbox.els.root.classList.add('single');
      },

      preload: () => {
        settings.preload.forEach((sequence, index) => {
          const item = (lightbox.current + sequence + lightbox.count - 1) % lightbox.count + 1;
          const el = Array.prototype.slice.call(lightbox.els.items, item - 1, item)[0];

          for (let img of el.querySelectorAll('img[data-src]')) {
            const src = img.dataset.src;

            img.addEventListener('load', () => {
              if (sequence === 0) lightbox.captions(el);
              el.classList.add('loaded');
            });

            delete img.dataset.src;
            img.src = src;
          }
        });
      },

      captions: next => {
        if (!next) next = Array.prototype.slice.call(lightbox.els.items, lightbox.current - 1, lightbox.current);

        const figure = next.querySelector('figure');
        const hm = (next.offsetWidth - figure.offsetWidth) / 2;
        const vm = (next.offsetHeight - figure.offsetHeight) / 2;

        const figcaption = next.querySelector('figcaption');

        if (hm > 260) {
          next.classList.remove('long');
          next.classList.add('slim');

          if (figcaption) {
            figcaption.style.width = `${hm}px`;
          }
        } else {
          next.classList.remove('long', 'slim');

          if (figcaption) {
            figcaption.style.width = '';
            if (vm + (parseInt(next.style.bottom, 10) || 0) - 15 < figcaption.offsetHeight) next.classList.add('long');
          }
        }
      },

      show: (next, direction) => {
        if (lightbox.running || next === lightbox.current) return false;

        lightbox.next = next;

        lightbox.trigger('change');

        lightbox.running = true;

        let dirvalue;
        if (direction) {
          dirvalue = (direction === 'next') ? 1 : -1;
        } else {
          dirvalue = (next > lightbox.current) ? 1 : -1;
        }

        const currentItem = Array.prototype.slice.call(lightbox.els.items, lightbox.current - 1, lightbox.current)[0];
        const nextItem = Array.prototype.slice.call(lightbox.els.items, next - 1, next)[0];

        currentItem.style.transform = `translateX(${-dirvalue * 100}%)`;
        nextItem.style.transform = `translateX(${dirvalue * 100}%)`;
        nextItem.style.display = 'block';

        setTimeout(() => (nextItem.style.transform = 'translateX(0)'), 0);

        lightbox.captions(nextItem);
        lightbox.previous = lightbox.current;
        lightbox.current = next;
        lightbox.els.current.innerHTML = nextItem.dataset.image;

        setTimeout(() => {
          currentItem.style.display = 'none';
          lightbox.running = false;
          lightbox.preload();
          lightbox.trigger('changed');
        }, 350);
      },

      step: direction => {
        const x = (direction === 'next') ? lightbox.current + 1 : lightbox.current - 1;
        const next = (x + lightbox.count - 1) % lightbox.count + 1;
        lightbox.show(next, direction);
      },

      trigger: (name, data) => {},

      addEventListener: (eventTarget, eventType, handler) => {
        lightbox.eventHandlers[eventTarget] = lightbox.eventHandlers[eventTarget] || {};
        lightbox.eventHandlers[eventTarget][eventType] = lightbox.eventHandlers[eventTarget][eventType] || [];
        lightbox.eventHandlers[eventTarget][eventType].push(handler);

        eventTarget.addEventListener(eventType, handler);
      },

      removeEventListeners: (eventTarget, eventType) => {
        for (let handler of ((lightbox.eventHandlers[eventTarget] || {})[eventType] || [])) {
          eventTarget.removeEventListener(eventType, handler);
        }

        lightbox.eventHandlers[eventTarget] && (lightbox.eventHandlers[eventTarget][eventType] = []);
      }
    };

    for (let item of document.querySelectorAll(selector)) {
      item.addEventListener('click', event => {
        event.preventDefault();
        event.stopPropagation();

        lightbox.open(item);
      });
    }
  }
}
