import './polyfills';
import './bg';
import './news';
import lightbox from './lightbox';
import './calendar';
import './menu';
import './forms';
import './recaptcha';

lightbox('.gallery a[rel="lightbox"]', {
  ofLabel: 'van'
});
