const form = document.getElementById('form');
const via = document.getElementById('via');

if (form) {
  const populateViaFieldHandler = event => {
    const h1 = document.querySelector('#right h1');
    if (h1) via.value = h1.textContent;
  };

  const validateForm = event => {
    event.preventDefault();

    let validated = true;
    for (const field of form.querySelectorAll('p.required')) {
      const el = field.querySelector('input[type="radio"]:checked,input[type="checkbox"]:checked,input:not([type="radio"]):not([type="checkbox"]),textarea');
      if (!el || el.value === '') {
        validated = false;
        field.classList.add('invalid');
      } else {
        field.classList.remove('invalid');
      }
    }

    if (validated) {
      if (via) populateViaFieldHandler(event);
      form.submit();
    }
  };

  form.addEventListener('submit', validateForm);
}
