import 'whatwg-fetch';

const root = document.getElementById('fullwidth');

if (root) {
  let selectedFilter;

  for (let element of root.querySelectorAll('.filter ul li a')) {
    element.addEventListener('click', event => {
      event.preventDefault();

      const previousFilter = selectedFilter;
      selectedFilter = element.classList.item(0);

      if (previousFilter !== selectedFilter) {
        previousFilter && root.classList.remove(previousFilter);
        selectedFilter && root.classList.add(selectedFilter);
      }
    });
  }

  if (document.getElementById('nextnews')) {
    const blocks = root.querySelector('.blocks');

    document.getElementById('nextnews').addEventListener('click', async event => {
      event.preventDefault();

      const start = blocks.querySelectorAll('.block').length;

      try {
        const response = await fetch(`/nieuwsload.php?start=${start}&max=12`);

        if (response.status === 200) {
          const body = await response.text();
          blocks.insertAdjacentHTML('beforeend', body);
          if (document.getElementById('newsend')) {
            document.getElementById('nextnews').remove();
          }
        }
      } catch (e) {
        console.log(e);
      }
    });
  }
}
