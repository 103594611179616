import 'whatwg-fetch';
import moment from 'moment';

moment.locale('nl');

const getCalendars = async (calendar, previous, removePreviousPar) => {
  let timeMin;
  let timeMax;
  let overlapMonth;

  if (calendar.dataset.timeMin) {
    if (previous) {
      timeMax = calendar.dataset.timeMin;

      if (!moment(timeMax).isSame(moment(timeMax).startOf('month'))) {
        timeMax = moment(timeMax).startOf('month').add(1, 'month').format();
        overlapMonth = true;
      }

      calendar.dataset.timeMin = (timeMin = moment(calendar.dataset.timeMin).startOf('month').add(-3, 'months').format());
    }
  } else {
    calendar.dataset.timeMin = (timeMin = moment().startOf('day').format());
    calendar.dataset.timeMax = (timeMax = moment().endOf('year').add(1, 'years').format());
  }

  const apiResponses = [];
  for (let calendarName of calendar.dataset.calendars.split(',')) {
    apiResponses.push(fetch(`https://www.googleapis.com/calendar/v3/calendars/${calendarName}/events?key=${calendar.dataset.key}&showDeleted=false&singleEvents=true&orderBy=startTime&timeMin=${encodeURIComponent(timeMin)}&timeMax=${encodeURIComponent(timeMax)}`));
  }

  const events = await (await Promise.all(apiResponses)).reduce(async (calendarEvents, apiResponse) => {
    calendarEvents = await calendarEvents;
    const apiResult = JSON.parse(await apiResponse.text());

    let summary = apiResult.summary;
    let label = '';

    if (summary === 'stichtslanglaufteam@gmail.com') {
      summary = 'Stichts Langlauf Team';
      label = 'stichtslanglaufteam';
    } else if (summary === 'Noordse Sporten Agenda') {
      label = 'noordsesportenagenda';
    }

    apiResult.items.forEach(item => {
      calendarEvents.push({
        calendar: summary,
        label: label,
        date: moment(item.start.date || item.start.dateTime).startOf('day'),
        endDate: item.end.date ? moment(item.end.date).startOf('day').add(-1, 'day') : moment(item.end.dateTime).startOf('day'),
        startTime: item.start.dateTime && moment(item.start.dateTime),
        endTime: item.end.dateTime && moment(item.end.dateTime),
        title: item.summary,
        description: normalizeDescription(item.description),
        location: item.location
      });
    });

    return calendarEvents;
  }, []);

  events.sort((a, b) => {
    let r = 0;

    if (a.date.isBefore(b.date)) {
      r = -1;
    } else if (a.date.isAfter(b.date)) {
      r = 1;
    } else if (a.endDate.isBefore(b.endDate)) {
      r = -1;
    } else if (a.endDate.isAfter(b.endDate)) {
      r = 1;
    } else if (!a.startTime && b.startTime) {
      r = -1;
    } else if (a.startTime && !b.startTime) {
      r = 1;
    } else if (a.startTime && b.startTime && a.startTime.isBefore(b.startTime)) {
      r = -1;
    } else if (a.startTime && b.startTime && a.startTime.isAfter(b.startTime)) {
      r = 1;
    }

    return r;
  });

  let currentMonth;
  let currentDay;
  let currentMonthDiv;

  if (events.length) {
    const monthDivs = events.reduce((monthDivs, event, index, arr) => {
      if (!currentMonth || !currentMonth.isSame(moment(event.date).startOf('month'))) {
        if (currentMonthDiv) {
          monthDivs.push(currentMonthDiv);
          currentMonthDiv = document.createElement('div');
        }

        currentMonthDiv || (currentMonthDiv = document.createElement('div'));

        currentMonth = moment(event.date).startOf('month');

        const headingDiv = document.createElement('div');
        headingDiv.classList.add('heading');
        currentMonthDiv.appendChild(headingDiv);

        const h1 = document.createElement('h1');
        h1.appendChild(document.createTextNode(currentMonth.format('MMMM YYYY')));
        headingDiv.appendChild(h1);
      }

      const eventDiv = document.createElement('div');
      eventDiv.classList.add('event');

      const timestampDiv = document.createElement('div');
      timestampDiv.classList.add('timestamp');

      if (!currentDay || !currentDay.isSame(moment(event.date).startOf('day')) || !moment(event.date).startOf('day').isSame(moment(event.endDate).startOf('day'))) {
        currentDay = moment(event.date).startOf('day');

        const dateDiv = document.createElement('div');
        dateDiv.classList.add('date');
        dateDiv.appendChild(document.createTextNode(event.date.format('dddd')));

        const largeSpan = document.createElement('span');
        largeSpan.classList.add('large');
        largeSpan.appendChild(document.createTextNode(event.date.format('D')));
        dateDiv.appendChild(largeSpan);

        timestampDiv.appendChild(dateDiv);

        if (!moment(event.date).startOf('day').isSame(moment(event.endDate).startOf('day'))) {
          const untilDiv = document.createElement('div');
          untilDiv.classList.add('until');
          untilDiv.appendChild(document.createTextNode('-'));
          timestampDiv.appendChild(untilDiv);

          const dateEndDiv = document.createElement('div');
          dateEndDiv.classList.add('date');
          dateEndDiv.classList.add('end');
          dateEndDiv.appendChild(document.createTextNode(event.endDate.format('dddd')));

          const largeSpan = document.createElement('span');
          largeSpan.classList.add('large');
          largeSpan.appendChild(document.createTextNode(event.endDate.format('D')));
          dateEndDiv.appendChild(largeSpan);

          timestampDiv.appendChild(dateEndDiv);
        }
      }

      if (event.startTime) {
        const timeDiv = document.createElement('div');
        timeDiv.classList.add('time');

        const startSpan = document.createElement('span');
        startSpan.classList.add('start');
        startSpan.appendChild(document.createTextNode(event.startTime.format('H:mm')));
        timeDiv.appendChild(startSpan);

        if (event.endTime) {
          const endSpan = document.createElement('span');
          endSpan.classList.add('end');
          endSpan.appendChild(document.createTextNode(event.endTime.format('H:mm')));
          timeDiv.appendChild(endSpan);
        }

        timestampDiv.appendChild(timeDiv);
      }

      eventDiv.appendChild(timestampDiv);

      const infoDiv = document.createElement('div');
      infoDiv.classList.add('info');

      const sourceSpan = document.createElement('span');
      sourceSpan.classList.add('source');
      event.label && sourceSpan.classList.add(event.label);
      sourceSpan.appendChild(document.createTextNode(event.calendar));
      infoDiv.appendChild(sourceSpan);

      const h2 = document.createElement('h2');
      h2.appendChild(document.createTextNode(event.title));
      infoDiv.appendChild(h2);

      if (event.location) {
        const h3 = document.createElement('h3');
        h3.appendChild(document.createTextNode(event.location));
        infoDiv.appendChild(h3);
      }

      if (event.description) {
        const descriptionDiv = document.createElement('div');
        descriptionDiv.classList.add('description');
        descriptionDiv.innerHTML = event.description;

        Array.prototype.slice.apply(descriptionDiv.querySelectorAll('span')).forEach(spanEl => {
          if (spanEl.hasChildNodes()) {
            let insertBefore = spanEl.nextSibling;
            Array.prototype.slice.apply(spanEl.childNodes).forEach(childNode => {
              spanEl.parentElement.insertBefore(childNode, insertBefore);
              insertBefore = childNode.nextSibling;
            });
          }
          spanEl.parentElement.removeChild(spanEl);
        });

        Array.prototype.slice.apply(descriptionDiv.querySelectorAll('p > br:first-child, p + br, br + br')).forEach(br => br.parentElement.removeChild(br));
        Array.prototype.slice.apply(descriptionDiv.querySelectorAll('p:empty')).forEach(p => p.parentElement.removeChild(p));
        [descriptionDiv.firstChild, descriptionDiv.lastChild].forEach(n => {
          if (n.tagName === 'BR') n.parentElement.removeChild(n);
        });

        Array.prototype.slice.apply(descriptionDiv.querySelectorAll('*')).forEach(el => {
          el.removeAttribute('style');
          el.removeAttribute('dir');
        });

        const replaceLinks = el => {
          if (el.hasChildNodes()) {
            Array.prototype.slice.apply(el.childNodes).forEach(node => {
              if ((node.nodeType === Node.TEXT_NODE) && node.textContent && (el.tagName !== 'A')) {
                const textContent = node.textContent;
                const re = /((?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-z0-9-]{2,5}[^\s]*[^\s.,])/g;
                const chunkedNodes = [];
                let reArray;
                let start = 0;

                while ((reArray = re.exec(textContent))) {
                  if (reArray.index) {
                    chunkedNodes.push(document.createTextNode(textContent.substring(start, reArray.index)));
                  }

                  const link = document.createElement('a');
                  link.href = reArray[1];
                  link.appendChild(document.createTextNode(reArray[1]));
                  chunkedNodes.push(link);

                  start = reArray.index + reArray[1].length;
                }

                if (start < textContent.length) {
                  chunkedNodes.push(document.createTextNode(textContent.substr(start)));
                }

                chunkedNodes.forEach(chunkedNode => {
                  el.insertBefore(chunkedNode, node);
                });

                el.removeChild(node);
              } else if (node.nodeType === Node.ELEMENT_NODE) {
                replaceLinks(node);
              }
            });
          }
        };

        replaceLinks(descriptionDiv);

        infoDiv.appendChild(descriptionDiv);
      }

      eventDiv.appendChild(infoDiv);

      currentMonthDiv.appendChild(eventDiv);

      if (index === (arr.length - 1)) {
        monthDivs.push(currentMonthDiv);
      }

      return monthDivs;
    }, []);

    if (previous) {
      monthDivs.reverse();
    }

    let scrollY = window.pageYOffset;
    let insertedHeight = 0;
    let computedStyles;
    let margin;

    if (removePreviousPar) {
      computedStyles = window.getComputedStyle(removePreviousPar);
      margin = parseFloat(computedStyles['margin-top']) + parseFloat(computedStyles['margin-bottom']);
      insertedHeight = -removePreviousPar.offsetHeight - margin;
      calendar.removeChild(removePreviousPar);
    }

    if (previous && overlapMonth) {
      computedStyles = window.getComputedStyle(calendar.firstChild);
      margin = parseFloat(computedStyles['margin-top']) + parseFloat(computedStyles['margin-bottom']);
      insertedHeight -= calendar.firstChild.offsetHeight + margin;
      calendar.removeChild(calendar.firstChild);
    }

    monthDivs.forEach(monthDiv => {
      if (previous) {
        calendar.insertBefore(monthDiv, calendar.firstChild);
        computedStyles = window.getComputedStyle(monthDiv);
        margin = parseFloat(computedStyles['margin-top']) + parseFloat(computedStyles['margin-bottom']);
        insertedHeight += monthDiv.offsetHeight + margin;
      } else {
        calendar.appendChild(monthDiv);
      }
    });

    const previousPar = document.createElement('p');
    previousPar.classList.add('previous');

    const previousLink = document.createElement('a');
    previousLink.href = '#';
    previousLink.appendChild(document.createTextNode('Verder terugkijken'));
    previousPar.appendChild(previousLink);

    calendar.insertBefore(previousPar, calendar.firstChild);

    computedStyles = window.getComputedStyle(previousPar);
    margin = parseFloat(computedStyles['margin-top']) + parseFloat(computedStyles['margin-bottom']);
    insertedHeight += previousPar.offsetHeight + margin;

    previous && window.scrollTo(0, scrollY + insertedHeight);

    previousLink.addEventListener('click', e => {
      e.preventDefault();

      getCalendars(calendar, true, previousPar);
    });
  }
};

const normalizeDescription = description => {
  return description && description.replace(/(?:&nbsp;)+/g, ' ').replace(/(?:\s*<br\s*\/?>\s*)+/g, '<br>').replace(/^(?:<br>)*/, '').replace(/(?:<br>)*$/, '');
};

const calendar = document.getElementById('calendar');

if (calendar && calendar.dataset.key && calendar.dataset.calendars) {
  (async () => {
    await getCalendars(calendar);
  })();
}
