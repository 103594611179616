(() => {
  const root = document.getElementById('bg');

  if (root) {
    const imgs = Array.prototype.slice.apply(root.querySelectorAll('img'));
    let current = 0;

    const next = () => {
      current = Math.floor(Math.random() * imgs.length);
      for (let img of imgs) {
        img.classList.remove('active');
      }
      imgs[current].classList.add('active');

      setTimeout(next, 12000);
    };

    next();
  }
})();
